<template>
    <div>
        <app-layout>
            <template v-slot:header>
                <Header :title="$t('student_courses_title')"
                        :isNewButton="false"
                        :isColumns="true"
                        @filter-div-status="datatable.filterStatus=$event"
                >
                    <template v-slot:columns>
                        <div class="mb-1" v-for="(column,key) in datatable.columns">
                            <b-form-checkbox
                                :id="'checkbox-'+key"
                                :name="'checkbox-'+key"
                                :value="false"
                                :unchecked-value="true"
                                v-model="column.hidden"
                                v-if="column.field!='buttons'"
                            >
                                {{ column.label }}
                            </b-form-checkbox>
                        </div>
                    </template>
                </Header>
            </template>
            <template v-slot:header-mobile>
                <HeaderMobile :title="$t('student_courses_title')"
                              :isNewButton="false"
                              :isColumns="true"
                              @filter-div-status="datatable.filterStatus=$event"
                >
                    <template v-slot:columns>
                        <div class="mb-1" v-for="(column,key) in datatable.columns">
                            <b-form-checkbox
                                :id="'checkbox-'+key"
                                :name="'checkbox-'+key"
                                :value="false"
                                :unchecked-value="true"
                                v-model="column.hidden"
                                v-if="column.field!='buttons'"
                            >
                                {{ column.label }}
                            </b-form-checkbox>
                        </div>
                    </template>
                </HeaderMobile>
            </template>
            <datatable-filter
                v-show="datatable.filterStatus"
                @filter="filter"
                @filterClear="filterClear"
                :export-excel="checkPermission('studentcourse_excelexport')"
                @exportExcel="downloadExcel">
                <b-row>
                    <!-- FILTER -- NATIONAL IDS -->
<!--                    <b-col sm="12" md="6" lg="3">-->
<!--                        <b-form-group :label="$t('national_number')">-->
<!--                            <multi-text v-model="datatable.queryParams.filter.national_id"-->
<!--                                        :only-number-and-enter="true"-->
<!--                            />-->
<!--                        </b-form-group>-->
<!--                    </b-col>-->

                    <!-- FILTER -- STUDENT NUMBERS -->
                    <b-col sm="12" md="6" lg="3">
                        <b-form-group :label="$t('student_number_long')">
                            <multi-text v-model="datatable.queryParams.filter.student_number"
                                        :only-number-and-enter="true"
                            />
                        </b-form-group>
                    </b-col>

                    <!-- FILTER -- ACADEMIC YEARS -->
                    <b-col sm="12" md="6" lg="3">
                        <b-form-group :label="$t('academic_year')">
                            <academic-years-selectbox v-model="datatable.queryParams.filter.academic_year"
                                                      :multiple="true"></academic-years-selectbox>
                        </b-form-group>
                    </b-col>

                    <!-- FILTER -- SEMESTERS -->
                    <b-col sm="12" md="6" lg="3">
                        <b-form-group :label="$t('semester')">
                            <parameter-selectbox code="semester" v-model="datatable.queryParams.filter.semester"
                                                 :multiple="true"></parameter-selectbox>
                        </b-form-group>
                    </b-col>

                    <!-- FILTER -- COURSE CODES -->
                    <b-col sm="12" md="6" lg="3">
                        <b-form-group :label="$t('course_code')">
                            <multi-text v-model="datatable.queryParams.filter.course_code"></multi-text>
                        </b-form-group>
                    </b-col>

                    <!-- FILTER -- COURSE NAMES -->
                    <b-col sm="12" md="6" lg="3">
                        <b-form-group :label="$t('course_name')">
                            <multi-text v-model="datatable.queryParams.filter.course_name"></multi-text>
                        </b-form-group>
                    </b-col>

                    <!-- FILTER -- SECTIONS -->
                    <b-col sm="12" md="6" lg="3">
                        <b-form-group :label="$t('section')">
                            <multi-text v-model="datatable.queryParams.filter.section"></multi-text>
                        </b-form-group>
                    </b-col>

                    <!-- FILTER -- LAB SECTIONS -->
<!--                    <b-col sm="12" md="6" lg="3">-->
<!--                        <b-form-group :label="$t('lab_section')">-->
<!--                            <multi-text v-model="datatable.queryParams.filter.lab_section_id"></multi-text>-->
<!--                        </b-form-group>-->
<!--                    </b-col>-->

                    <!-- FILTER -- SECTION LANGUAGES -->
<!--                    <b-col sm="12" md="6" lg="3">-->
<!--                        <b-form-group :label="$t('language')">-->
<!--                            <parameter-selectbox code="languages" v-model="datatable.queryParams.filter.section_language"-->
<!--                                                :multiple="true"></parameter-selectbox>-->
<!--                        </b-form-group>-->
<!--                    </b-col>-->

                    <!-- FILTER -- COURSE TYPES -->
                    <b-col sm="12" md="6" lg="3">
                        <b-form-group :label="$t('course_type')">
                            <parameter-selectbox code="course_types" size="sm"
                                                 v-model="datatable.queryParams.filter.course_type"
                                                 :multiple="true"></parameter-selectbox>
                        </b-form-group>
                    </b-col>

                    <!-- FILTER -- GRADE SYSTEMS / LETTER GRADE / GRADE SCALA -->
                    <b-col sm="12" md="6" lg="3">
                        <b-form-group :label="$t('grade_scala')">
                            <grade-system-selectbox size="sm"
                                                    v-model="datatable.queryParams.filter.grading_system_id"
                            />
                        </b-form-group>
                    </b-col>

                    <!-- FILTER -- SLOT TYPES -->
                    <b-col sm="12" md="6" lg="3">
                        <b-form-group :label="$t('slot_type')">
                            <slot-type-selectbox size="sm" v-model="datatable.queryParams.filter.slot_type"
                                                 :multiple="true"></slot-type-selectbox>
                        </b-form-group>
                    </b-col>

                    <!-- FILTER -- NATIONAL CREDIT BETWEEN -->
                    <b-col sm="12" md="6" lg="3">
                        <b-form-group :label="$t('credit')">
                            <credit-between size="sm"
                                            v-model="datatable.queryParams.filter.credit_between"></credit-between>
                        </b-form-group>
                    </b-col>

                    <!-- FILTER -- REGISTRATION TYPES / GIRIS TURU -->
                    <b-col sm="12" md="6" lg="3">
                        <b-form-group :label="$t('registration_type')">
                            <parameter-selectbox code="registration_types" size="sm"
                                                 v-model="datatable.queryParams.filter.registration_type"
                                                 :multiple="true"></parameter-selectbox>
                        </b-form-group>
                    </b-col>

                    <!-- FILTER -- STUDENT STATUSES -->
                    <b-col sm="12" md="6" lg="3">
                        <b-form-group :label="$t('student_status')">
                            <parameter-selectbox code="student_statuses" size="sm"
                                                 v-model="datatable.queryParams.filter.student_status"
                                                 :multiple="true"></parameter-selectbox>
                        </b-form-group>
                    </b-col>

                    <!-- FILTER -- SEMESTER STATUSES -->
                    <b-col sm="12" md="6" lg="3">
                        <b-form-group :label="$t('semester_status')">
                            <parameter-selectbox code="semester_statuses" size="sm"
                                                 v-model="datatable.queryParams.filter.semester_status"
                                                 :multiple="true"></parameter-selectbox>
                        </b-form-group>
                    </b-col>

                    <!-- FILTER -- FACULTIES -->
                    <b-col sm="12" md="6" lg="3">
                        <b-form-group :label="$t('faculty')">
                            <faculty-selectbox size="sm" v-model="datatable.queryParams.filter.faculty_code"
                                               :multiple="true"></faculty-selectbox>
                        </b-form-group>
                    </b-col>

                    <!-- FILTER -- PROGRAMS -->
                    <b-col sm="12" md="6" lg="6">
                        <b-form-group :label="$t('program')">
                            <program-selectbox size="sm" v-model="datatable.queryParams.filter.student_program_id" :faculty_code="datatable.queryParams.filter.faculty_code"
                                               :department_code="datatable.queryParams.filter.department_code" :multiple="true"></program-selectbox>
                        </b-form-group>
                    </b-col>

                    <!-- FILTER -- INSTRUCTORS -->
<!--                    <b-col sm="12" md="6" lg="3">-->
<!--                        <b-form-group :label="$t('instructor')">-->
<!--                            <multi-text v-model="datatable.queryParams.filter.user_id"></multi-text>-->
<!--                        </b-form-group>-->
<!--                    </b-col>-->

                    <!-- FILTER -- DEPARTMENTS -->
                    <b-col sm="12" md="6" lg="3">
                        <b-form-group :label="$t('department')">
                            <department-selectbox size="sm" v-model="datatable.queryParams.filter.department_code"
                                                  :faculty_code="datatable.queryParams.filter.faculty_code"
                                                  :multiple="true"></department-selectbox>
                        </b-form-group>
                    </b-col>


                    <!-- FILTER -- CLASSES -->
                    <b-col sm="12" md="6" lg="3">
                        <b-form-group :label="$t('class')">
                            <parameter-selectbox code="classes" size="sm" v-model="datatable.queryParams.filter.class"
                                                 :multiple="true"></parameter-selectbox>
                        </b-form-group>
                    </b-col>

                    <!-- FILTER -- GRADES -->
                    <b-col sm="12" md="6" lg="3">
                        <b-form-group :label="$t('letter_grade')">
                            <grade-selectbox v-model="datatable.queryParams.filter.letter_grade"
                                             :grading_system_id="datatable.queryParams.filter.grading_system_id"
                                             :multiple="true"
                                             valueType="grade"
                            />
                        </b-form-group>
                    </b-col>
                </b-row>
            </datatable-filter>
            <datatable :isLoading.sync="datatable.isLoading"
                       :columns="datatable.columns"
                       :rows="datatable.rows"
                       :total="datatable.total"
                       :queryParams="datatable.queryParams"
                       @on-page-change="onPageChange"
                       @on-sort-change="onSortChange"
                       @on-per-page-change="onPerPageChange"
                       v-show="datatable.showTable"
            >
            </datatable>
        </app-layout>
    </div>
</template>
<script>
    import AppLayout from '@/layouts/AppLayout'
    import Header from '@/layouts/AppLayout/Header'
    import HeaderMobile from '@/layouts/AppLayout/HeaderMobile'
    import DatatableFilter from '@/components/datatable/DatatableFilter';
    import Datatable from '@/components/datatable/Datatable';
    import MultiText from '@/components/interactive-fields/MultiText';
    import AcademicYearsSelectbox from '@/components/interactive-fields/AcademicYearsSelectbox';
    import ParameterSelectbox from '@/components/interactive-fields/ParameterSelectbox';
    import GradeSystemSelectbox from '@/components/interactive-fields/GradeSystemSelectbox';
    import SlotTypeSelectbox from '@/components/interactive-fields/SlotTypeSelectbox';
    import CreditBetween from '@/components/interactive-fields/CreditBetween';
    import FacultySelectbox from '@/components/interactive-fields/FacultySelectbox';
    import DepartmentSelectbox from '@/components/interactive-fields/DepartmentSelectbox';
    import ProgramSelectbox from '@/components/interactive-fields/ProgramSelectbox';
    import GradeSelectbox from '@/components/interactive-fields/GradeSelectbox';
    import StudentCourseService from '@/services/StudentCourseService';
    // import {ValidationProvider, ValidationObserver} from "vee-validate"
    import qs from 'qs'

    export default {
        components: {
            AppLayout,
            Header,
            HeaderMobile,
            DatatableFilter,
            Datatable,
            MultiText,
            AcademicYearsSelectbox,
            ParameterSelectbox,
            GradeSystemSelectbox,
            SlotTypeSelectbox,
            CreditBetween,
            FacultySelectbox,
            DepartmentSelectbox,
            ProgramSelectbox,
            GradeSelectbox
        },
        metaInfo() {
            return {
                title: this.$t('student_courses_title')
            }
        },
        data() {
            return {
                datatable: {
                    isLoading: false,
                    columns: [
                        {
                            label: this.$t('id'),
                            field: 'id',
                            hidden: true
                        },
                        {
                            label: this.$t("national_id"),
                            field: 'national_id',
                            hidden: false,
                            sortable: false
                        },
                        {
                            label: this.$t('student_number'),
                            field: 'student_number',
                            hidden: false,
                            sortable: false
                        },
                        {
                            label: this.$t('name'),
                            field: 'student_name',
                            hidden: false,
                            sortable: false
                        },
                        {
                            label: this.$t('surname'),
                            field: 'student_surname',
                            hidden: false,
                            sortable: false
                        },
                        {
                            label: this.$t("email"),
                            field: 'email',
                            hidden: false,
                            sortable: false
                        },
                        {
                            label: this.$t("academic_year"),
                            field: 'academic_year',
                            hidden: false,
                            sortable: false
                        },
                        {
                            label: this.$t('semester'),
                            field: 'semester',
                            hidden: false,
                            sortable: false
                        },
                        {
                            label: this.$t('course_code'),
                            field: 'course_code',
                            hidden: false,
                            sortable: false
                        },
                        {
                            label: this.$t('course_name'),
                            field: 'course_name',
                            hidden: false,
                            sortable: false
                        },
                        {
                            label: this.$t("section"),
                            field: 'section',
                            hidden: false,
                            sortable: false
                        },
                        // {
                        //     label: this.$t('lab_section'),
                        //     field: 'lab_section_number',
                        //     hidden: false,
                        //     sortable: false
                        // },
                        // {
                        //     label: this.$t('language'),
                        //     field: 'section_language',
                        //     hidden: false,
                        //     sortable: false
                        // },
                        {
                            label: this.$t('course_type'),
                            field: ('course_type'),
                            hidden: false,
                            sortable: false
                        },
                        // email
                        {
                            label: this.$t("grade_scala"),
                            field: 'grade_system_id',
                            hidden: false,
                            sortable: false
                        },
                        {
                            label: this.$t("slot_type"),
                            field: ('slot_type_name'),
                            hidden: false,
                            sortable: false
                        },
                        {
                            label: this.$t('credit'),
                            field: 'credit',
                            hidden: false,
                            sortable: false
                        },
                        {
                            label: this.$t("registration_type"),
                            field: ('registration_type_name'),
                            hidden: false,
                            sortable: false
                        },
                        {
                            label: this.$t("grade_scala"),
                            field: 'grading_system_name',
                            hidden: false,
                            sortable: false
                        },
                        {
                            label: this.$t('student_status'),
                            field: 'student_status',
                            hidden: false,
                            sortable: false
                        },
                        {
                            label: this.$t('semester_status'),
                            field: 'semester_status',
                            hidden: false,
                            sortable: false
                        },
                        {
                            label: this.$t('faculty'),
                            field: 'faculty_name',
                            hidden: false,
                            sortable: false
                        },
                        {
                            label: this.$t('department_code'),
                            field: 'department_code',
                            hidden: false,
                            sortable: false
                        },
                        {
                            label: this.$t('program_code'),
                            field: 'student_program_id',
                            hidden: false,
                            sortable: false
                        },
                        {
                            label: this.$t('class'),
                            field: 'class',
                            hidden: false,
                            sortable: false
                        },
                        // {
                        //     label: this.$t('instructor'),
                        //     field: 'instructor_name',
                        //     hidden: false,
                        //     sortable: false
                        // },
                        {
                            label: this.$t('letter_grade'),
                            field: 'letter_grade',
                            hidden: false,
                            sortable: false
                        }
                    ],
                    rows: [],
                    total: 0,
                    filterStatus: true,
                    showTable: false,
                    queryParams: {
                        filter: {},
                        sort: '-id',
                        page: 1,
                        limit: 20
                    }
                },
                formProcess: null,
                formId: null,
                form: {}
            }
        },
        created() {
            this.filterSet();
        },
        methods: {
            filterSet() {
                this.datatable.queryParams.filter = {
                    student_number: '',
                    academic_year: '',
                    semester: '',
                    course_code: '',
                    course_name: '',
                    section_id: '',
                    lab_section_id: '',
                    section_language: '',
                    course_type: '',
                    grading_system_id: '',
                    slot_type: '',
                    credit_between: '',
                    registration_type: '',
                    student_status: '',
                    semester_status: '',
                    faculty_code: '',
                    department_code: '',
                    student_program_id: '',
                    class: '',
                    instructor_id: '',
                    letter_grade: ''
                };
            },
            filterClear() {
                this.filterSet()
                this.getRows();
            },
            filter() {
                this.datatable.queryParams.page = 1;
                this.getRows();
            },
            onPageChange(params) {
                this.datatable.queryParams.page = params.currentPage;
                this.getRows();
            },
            onPerPageChange(params) {
                this.datatable.queryParams.limit = params.currentPerPage;
                this.datatable.queryParams.page = 1;
                this.getRows();
            },
            changeColumn(key) {
                this.datatable.columns[key].hidden = this.datatable.columns[key].hidden == true ? false : true;
            },
            onSortChange(params) {
                const sortType = params[0].type == 'desc' ? '-' : '';
                this.datatable.queryParams.sort = sortType + params[0].field
                this.getRows();
            },
            getRows() {
                this.datatable.showTable = true;
                this.datatable.isLoading = true;

                const config = {
                    params: {
                        ...this.datatable.queryParams
                    },
                    paramsSerializer: (params) => qs.stringify(params, {encode: false})
                };

                // Remove empty strings from the filter
                const filter = {}
                for (const [k, v] of Object.entries(this.datatable.queryParams.filter)) {
                    if (v && v.length > 0) filter[k] = v
                }
                config.params.filter = filter

                // Empty Filters
                if (JSON.stringify(config.params.filter) === '{}') {
                    this.datatable.rows = []
                    this.datatable.total = 0
                    this.datatable.isLoading = false;
                    return
                }

                // FIXME: API Unknown filter options

                // API sometimes returns following error: (not always)
                // Undefined column: 7 ERROR:  column "user_id" does not exist
                return StudentCourseService.getAll(config)
                                           .then((response) => {
                                               this.datatable.rows = response.data.data
                                               this.datatable.total = response.data.pagination.total
                                           })
                                           .finally(() => {
                                               this.datatable.isLoading = false;
                                           });
            },
            downloadExcel() {
                const config = {
                    params: {
                        ...this.datatable.queryParams
                    },
                    paramsSerializer: (params) => qs.stringify(params, {encode: false})
                };

                StudentCourseService.downloadAsExcel(config)

                                    .then(res => this.$router.push('/job/logs/detail/' + res.data.data.log_id))
                                    .catch(err => this.showErrors(err))
            }
        }
    }
</script>

